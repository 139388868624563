import { gql } from '@apollo/client';
import { redirectToPaymentGateway } from '../../../queries_mutations/paymentGateway/mutations';

export const CREATE_COPY_REQUEST = gql`
  mutation createCopyRequest($data: CopyRequestInput!) {
    copyRequest {
      add(data: $data) {
        id
      }
    }
  }
`;
export const CREATE_PURCHASE_TOKEN = gql`
  mutation Mutation($applicationID: String, $copyRequestID: String) {
    copyRequest {
      createPurchaseToken(
        applicationID: $applicationID
        copyRequestID: $copyRequestID
      )
    }
  }
`;
export const CREATE_ADDRESSES = gql`
  mutation createCopyRequestAddresses($data: [addressInput]) {
    copyRequest {
      addAddresses(addresses: $data) {
        id
      }
    }
  }
`;

export default client => {
  const createCopyRequest = copyRequestData => {
    // console.log('copy request data from createCopyRequest', copyRequestData);
    const { addresses, emailPDFS, applicationId, returnDocs } = copyRequestData;
    const emailArray = JSON.stringify(emailPDFS.map(email => email));
    const returnDocsString = JSON.stringify(returnDocs);

    const variables = {
      data: {
        applicationId,
        emails: emailArray,
        returnDocs: returnDocsString,
      },
    };
    return client
      .mutate({
        mutation: CREATE_COPY_REQUEST,
        variables,
      })
      .then(async (data, error) => {
        if (error) {
          throw new Error();
        }
        const copyRequestId = data.data.copyRequest.add.id;
        if (addresses.length > 0) {
          const addressesWithCopyRequestID = addresses.map(address => {
            const addressWorking = address;
            delete addressWorking.type;
            return {
              ...addressWorking,
              copyRequestId,
            };
          });
          const address = await client.mutate({
            mutation: CREATE_ADDRESSES,
            variables: { data: addressesWithCopyRequestID },
          });
          return { address, copyRequestId };
        }
        return { copyRequestId };
      });
  };

  const payCopyRequest = ({ applicationID, copyRequestID }) => {
    return client
      .mutate({
        mutation: CREATE_PURCHASE_TOKEN,
        variables: {
          applicationID,
          copyRequestID,
        },
      })
      .then(({ data }, error) => {
        if (error) {
          throw new Error(error);
        }
        const { createPurchaseToken: token } = data.copyRequest;
        return redirectToPaymentGateway(token);
      });
  };

  return {
    createCopyRequest,
    payCopyRequest,
  };
};
