import React from 'react';
import styled from 'styled-components';
import RoundedBlock from '../../UI/RoundedBlock/RoundedBlock';
import { returnService, constructAddress } from '../../../shared/functions';
import { Button, ButtonsCont } from '../../../shared/styles';

const CopyRequestReviewPayment = ({ formState, send }) => {
  const {
    addresses,
    services,
    emailPDFS,
    emailService,
    totalPrice,
    returnDocs,
    returnDocsServices,
    returnDocsEmailService,
  } = formState.context;
  return (
    <CopyRequestReviewPaymentStyled>
      <h1>Payment Summary</h1>
      <RoundedBlock title="Order Information">
        <div className="__block __table-header">
          <div>
            <p>SERVICE</p>
          </div>
          <div>
            <p>PRICE</p>
          </div>
        </div>
        {addresses.length > 0 && (
          <>
            {addresses.map((address, index) => (
              <div className="__block __service" key={Math.random()}>
                <div>
                  <h3>Address {index + 1}</h3>
                  <div className="__address">
                    {constructAddress(address)}
                    Shipping Method:{' '}
                    {
                      returnService(services, address.shippingMethod)
                        .displayName
                    }
                  </div>
                </div>
                <div>
                  <p>
                    <span>$</span>
                    {returnService(services, address.shippingMethod).price}.00
                  </p>
                </div>
              </div>
            ))}
          </>
        )}
        {emailPDFS.length > 0 &&
          emailPDFS.map((email, index) => (
            <div className="__block __service" key={Math.random()}>
              <div>
                <h3>Email {index + 1}</h3>
                <p>{email.email}</p>
              </div>
              <div>
                <p>
                  <span>$</span>
                  {emailService.price}.00
                </p>
              </div>
            </div>
          ))}
        {returnDocs?.returnEmail && (
          <div className="__block __service" key={Math.random()}>
            <div>
              <h3>Return Docs Email</h3>
              <p>{returnDocs.returnEmail}</p>
            </div>
            <div>
              <p>
                <span>$</span>
                {returnDocsEmailService.price}.00
              </p>
            </div>
          </div>
        )}
        {returnDocs?.shipping_method && (
          <div className="__block __service" key={Math.random()}>
            <div>
              <h3>Return Documents Address</h3>
              <div>{constructAddress(returnDocs)}</div>
            </div>
            <div>
              <p>
                <span>$</span>
                {
                  returnService(returnDocsServices, returnDocs.shipping_method)
                    .price
                }
                .00
              </p>
            </div>
          </div>
        )}
        <div className="__block __service __total">
          <div>
            <h3>Total:</h3>
          </div>
          <div>
            <h3>
              <span>$</span>
              {totalPrice}.00
            </h3>
          </div>
        </div>
      </RoundedBlock>
      <h2 className="error copyRequest_errormsg">
        Imporant! After submitting payment please click the "Continue" button in
        the top right corner of the receipt page.
      </h2>
      <ButtonsCont className="__button-cont">
        <Button outline onClick={() => send('PREVIOUS')}>
          Previous
        </Button>
        <Button onClick={() => send('COMPLETE')}>Continue To Payment</Button>
      </ButtonsCont>
    </CopyRequestReviewPaymentStyled>
  );
};

export default CopyRequestReviewPayment;

const CopyRequestReviewPaymentStyled = styled.div`
  .copyRequest_errormsg {
    color: red;
    margin-top: 20px;
  }
  h1 {
    margin-bottom: 30px;
  }
  .__header {
    background-color: #f7fafc;
  }
  .__content {
    padding: 0;
  }
  .__block {
    display: flex;
    justify-content: left;
    align-items: center;
    box-sizing: content;
    padding: 0 30px;
    background-color: #f7fafc;
    p {
      color: #14527f;
      font-size: 15px;
      font-weight: 700;
      text-transform: uppercase;
      margin-bottom: 0;
    }
    &.__table-header {
      div {
        border-right: 1px solid #d4d4d4;
        padding: 10px 0;
        flex: 1;
        &:last-child {
          padding-left: 10px;
          border-right: none;
        }
      }
    }
    &.__service {
      align-items: center;
      div {
        flex: 1;
        padding: 10px 0;
        border-right: 1px solid #d4d4d4;
        &:last-child {
          padding-left: 10px;
          border-right: none;
        }
        p {
          color: black;
          font-weight: 400;
        }
      }
      .__address {
        padding-left: 30px;
        border-right: none;
      }
    }
    .__section-header {
      border-right: solid 1px #d4d4d4;
    }
  }
  .__total {
    padding-bottom: 0;

    background-color: #edf2f7;

    h3 {
      color: #14527f;
    }
    div:first-child {
      h3 {
        text-align: right;
        padding-right: 10px;
      }
    }
  }
  h2 {
    font-size: 16px;
    font-weight: 700;
  }
  .__button-cont {
    margin-top: 30px;
    margin-left: -10px;
  }
`;
