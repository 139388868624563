import React, { useEffect } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';

// import { messages, toast } from '../../shared/notifications';

const ProtectedRoute = ({ children }) => {
  const { isAuthenticated, isLoading, user } = useAuth0();

  useEffect(() => {
    // toast.pop(messages.paymentNotice);
  }, []);

  if (!isLoading) {
    // user isn't authenticated and is required to be.
    if (!isAuthenticated) {
      return <Navigate to="/" replace />;
    }

    // user hasn't verified their login email address yet.
    if (user.email_verified === false) return <Navigate to="/verify-email" />;

    return children || <Outlet />;
  }

  return null;
};

export default ProtectedRoute;
