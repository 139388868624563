import React from 'react';
import dayjs from 'dayjs';
import styled from 'styled-components';

import { ReactComponent as IconEdit } from '../../assets/edit.svg';
import { ReactComponent as Trash } from '../../assets/trashicon.svg';

const TableStyled = styled.table`
  width: 100%;
  border-collapse: collapse;
  th {
    padding: 15px;
    color: #14527f;
    text-transform: uppercase;
    font-size: 15px;
    border-right: solid 2.5px #d4d4d4;
    &:last-child {
      border-right: none;
    }
    @media print {
      font-size: 10px;
      color: black;
      width: 16.66%;
      padding: 0;
      margin: 0;
    }
  }
  td {
    padding: 15px;
    border-right: solid 2.5px #d4d4d4;
    text-align: center;
    &:last-child {
      border: none;
    }
    @media print {
      font-size: 12px;
      color: black;
      width: 16.66%;
      padding: 0;
      margin: 0;
    }
  }
  .school__actions {
    display: flex;
    @media only screen and (max-width: 1150px) {
      flex-direction: column;
    }
    button {
      border: none;
      background-color: transparent;
      cursor: pointer;
      margin-bottom: 10px;
      padding: 0 5px;
      border-right: solid 1px #d4d4d4;
      display: flex;
      align-items: center;
      transition: all ease 0.3s;
      &:first-child {
        svg {
          margin-right: 3px;
        }
      }
      &:last-child {
        border-right: none;
      }
      svg {
        fill: #2e2e2e;
      }
      &:hover {
        color: red;
        svg {
          transition: all ease 0.3s;
          fill: red !important;
        }
      }
    }
  }
`;
const SchoolList = ({ schools, fromDB, editable, send }) => {
  return (
    <TableStyled>
      <thead>
        <tr>
          <th>country</th>
          <th>school/institution</th>
          <th>area of study</th>
          <th>attendance</th>
          <th>degree/diploma</th>
          <th>award date</th>
          {editable && <th>Actions</th>}
        </tr>
      </thead>
      <tbody>
        {schools &&
          schools.map(
            ({
              country,
              name,
              school,
              areaOfStudy,
              study,
              startDate,
              endDate,
              awardDate,
              degree,
              degreeDate,
              id,
            }) => (
              <tr className="school" key={`${id}${country}`}>
                <td>{country}</td>
                <td className="school__name">{fromDB ? school : name}</td>
                <td>{fromDB ? study : areaOfStudy}</td>
                {fromDB ? (
                  <td>
                    {dayjs(startDate).format('MM/YYYY')} -{' '}
                    {dayjs(endDate).format('MM/YYYY')}
                  </td>
                ) : (
                  <td>{`${startDate.month}/${startDate.year} - ${endDate.month}/${endDate.year} `}</td>
                )}
                <td>
                  {fromDB
                    ? degree || 'N/A'
                    : degree.notApplicable
                    ? 'N/A'
                    : degree.title}
                </td>
                {fromDB ? (
                  <td>
                    {degreeDate ? dayjs(degreeDate).format('MM/YYYY') : '--'}
                  </td>
                ) : awardDate ? (
                  <td>{`${awardDate?.month}/${awardDate?.year}`}</td>
                ) : (
                  <td>--</td>
                )}
                {editable && (
                  <td className="school__actions">
                    <button
                      type="button"
                      onClick={() => send('EDIT_SCHOOL', { id })}
                    >
                      <IconEdit />
                      Edit
                    </button>
                    <button
                      type="button"
                      onClick={() => send('DELETE_SCHOOL', { id })}
                    >
                      <Trash />
                    </button>
                  </td>
                )}
              </tr>
            ),
          )}
      </tbody>
    </TableStyled>
  );
};

export default SchoolList;
