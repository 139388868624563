import React from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import './App.css';
import ProtectedRoute from './components/auth/ProtectedRoute';
import ProtectedAdminRoute from './components/auth/ProtectedAdminRoute';
import { ValidateProfileAddContext } from './components/auth/ValidateProfileAddContext';

import ClientApplicationPage from './pages/ClientApplicationPage';
import LoginPage from './pages/LoginPage';
import LoginSuccess from './pages/LoginSuccess';
import DashboardContact from './pages/DashboardContact';
import AdminServicesSettings from './pages/AdminPages/AdminServicesSettings';
import AdminCopyRequestListPage from './pages/AdminPages/AdminCopyRequestListPage';
import AdminPendingCopyRequestListPage from './pages/AdminPages/AdminCopyRequestPendingList';
import AdminCopyRequestReviewPage from './pages/AdminPages/AdminCopyRequestReviewPage';
import AdminConfigureSettings from './pages/AdminPages/AdminConfigureSettings';
import AdminEmailTemplates from './pages/AdminPages/AdminEmailTemplates';
import AdminEvaluationsList from './pages/AdminPages/AdminEvaluationsList';
import AdminEvaluationsListMy from './pages/AdminPages/AdminEvaluationsListMy';
import ReviewEvaluation from './pages/AdminPages/ReviewEvaluation';
import IncompleteApplicationsList from './pages/AdminPages/IncompleteApplicationsList';
import DashboardApplicationView from './pages/DashboardApplicationView';
import DashboardTerms from './pages/DashboardTerms';
import DashboardPrivacy from './pages/DashboardPrivacy';
import CreateProfilePage from './pages/CreateProfilePage';
import VerifyEmailPage from './pages/VerifyEmail';
import UserSettingsPage from './pages/UserSettingsPage';
import CopyRequestPage from './pages/CopyRequestPage';
import UpgradeRushPage from './pages/UpgradeRushPage';
import ApplicationViewPage from './pages/AdminPages/ApplicationViewPage';
import IncompleteApplicationViewPage from './pages/AdminPages/IncompleteApplicationView';
import RenewEvaluation from './pages/RenewEvaluation';
import Logout from './pages/Logout';
import AccessDenied from './pages/AccessDenied';
import ApplicationView from './pages/ApplicationView';

function App() {
  const router = createBrowserRouter([
    // Anon Pages
    { path: '/', element: <LoginPage /> },
    { path: '/logout', element: <Logout /> },

    // TODO: Do these three really need to be secure?
    { path: '/contact', element: <DashboardContact /> },
    { path: '/terms', element: <DashboardTerms /> },
    { path: '/privacy-policy', element: <DashboardPrivacy /> },
    { path: '/access-denied', element: <AccessDenied /> },
    { path: '/verify-email', element: <VerifyEmailPage /> },

    // Secure Pages Below
    {
      element: <ProtectedRoute />,
      children: [
        { path: '/login-success', element: <LoginSuccess /> },
        { path: '/create-profile', element: <CreateProfilePage /> },
        {
          element: <ValidateProfileAddContext />,
          children: [
            { path: '/application', element: <ClientApplicationPage /> },

            // Settings Pages

            { path: '/settings', element: <UserSettingsPage /> },

            // Dashboard Pages
            { path: '/dashboard', element: <DashboardApplicationView /> },

            {
              path: '/dashboard/:status',
              element: <DashboardApplicationView />,
            },

            {
              path: '/dashboard/copy-request/:id',
              element: <CopyRequestPage />,
            },

            {
              path: '/dashboard/upgrade-rush/:id',
              element: <UpgradeRushPage />,
            },

            {
              path: '/dashboard/request-review/:id',
              element: <RenewEvaluation />,
            },
            {
              path: '/dashboard/app-view/:id',
              element: <ApplicationView />,
            },

            // Admin Pages
            {
              path: '/admin',
              element: <ProtectedAdminRoute />,
              children: [
                { path: 'evaluations', element: <AdminEvaluationsList /> },
                { path: 'my-evaluations', element: <AdminEvaluationsListMy /> },

                {
                  path: 'incomplete-applications',
                  element: <IncompleteApplicationsList />,
                },
                {
                  path: 'copy-requests',
                  element: <AdminCopyRequestListPage />,
                },
                {
                  path: 'pending-copy-requests',
                  element: <AdminPendingCopyRequestListPage />,
                },

                {
                  path: 'evaluation-review',
                  children: [{ path: ':id', element: <ReviewEvaluation /> }],
                },
                {
                  path: 'application',
                  children: [{ path: ':id', element: <ApplicationViewPage /> }],
                },
                {
                  path: 'incomplete-application',
                  children: [
                    { path: ':id', element: <IncompleteApplicationViewPage /> },
                  ],
                },
                {
                  path: 'copy-request',
                  children: [
                    { path: ':id', element: <AdminCopyRequestReviewPage /> },
                  ],
                },
                {
                  path: 'settings',
                  children: [
                    { path: 'services', element: <AdminServicesSettings /> },
                    { path: 'configure', element: <AdminConfigureSettings /> },
                    {
                      path: 'email-templates',
                      element: <AdminEmailTemplates />,
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
  ]);

  return (
    <main className="App">
      <RouterProvider router={router} />
    </main>
  );
}

export default App;
