function configMissing(configKey) {
  throw new Error(`missing required environment setting: ${configKey}`);
}

const requiredConfig = {
  apiUrl: process.env.REACT_APP_API_URL || configMissing('REACT_APP_API_URL'),
};

export const debug = {
  xstate: process.env.REACT_APP_XSTATE_DEUBG === 'true',
  enableAbilityToDeleteApplication: process.env.REACT_APP_DELETE_APPLICATION,
};

export const requestReview = process.env.NODE_ENV === 'development' ? 0 : 45;

export const paymentGateway = {
  paymentUrl:
    process.env.REACT_APP_AUTHORIZE_DOT_NET_PAYMENT_URL ||
    configMissing('REACT_APP_AUTHORIZE_DOT_NET_PAYMENT_URL'),
};

export const auth0Config = {
  domain: 'gceus.us.auth0.com',
  clientId: 'JmIEEWt0FZEOw9bNyxF957KOYtHbvTxy',
  authorizationParams: {
    redirect_uri: `${window.location.origin}/login-success`,
    audience: 'https://gceus-api-usmkrvnr3q-uc.a.run.app',
    scopes: 'openid profile email offline_access',
  },
};

export const sentryConfig = {
  dsn: 'https://862b9ff1a4b74a90a19b625ec35b0f0b@o561794.ingest.sentry.io/5699590',
  debug: process.env.REACT_APP_SENTRY_DEBUG || false,
  environment: process.env.NODE_ENV,
};

export default requiredConfig;
