import { gql } from '@apollo/client';

export const GET_CART = gql`
  query Query($applicationId: String!) {
    applications {
      me {
        application(id: $applicationId) {
          getCart {
            total
            deliveryServiceOption {
              name
              id
              displayName
              description
              price
              serviceType
            }
            rushService {
              id
              name
              displayName
              description
              price
              serviceType
            }
            applicationService {
              id
              name
              displayName
              description
              price
              serviceType
              show
            }
            returnDocumentsService {
              id
              name
              displayName
              description
              price
              serviceType
              show
            }
            additionalPDFEmails
            additionalReportsMailedAddresses {
              id
              line1
              line2
              city
              state
              postalCode
              country
              location
              company
              contact
              shippingMethod
            }
          }
        }
      }
    }
  }
`;
export default client => {
  const getCart = async ({ variables }) => {
    return client
      .query({
        query: GET_CART,
        variables,
      })
      .then(({ data }, error) => {
        if (error) {
          throw new Error();
        }
        return data.applications.me.application.getCart;
      });
  };

  return {
    getCart,
  };
};
