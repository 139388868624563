function createConfig(id = '') {
  return {
    initial: 'init',
    context: {
      addresses: [],
      applicationId: id,
      services: [],
    },

    states: {
      init: {
        invoke: {
          src: 'getServices',
          onDone: {
            actions: ['addServices'],
            target: 'waiting',
          },
          onError: {
            target: 'loadingError',
          },
        },
      },
      waiting: {},
      calculatePrice: {
        entry: ['calculatePrice', 'sendSubmit'],
        on: {
          COMPLETE: 'copyRequestOverview',
        },
      },
      copyRequestOverview: {
        on: {
          COMPLETE: 'createCopyRequest',
          PREVIOUS: 'waiting',
        },
      },
      createCopyRequest: {
        invoke: {
          src: 'createCopyRequest',
          onDone: {
            actions: ['saveCopyRequestID'],
            target: 'paying',
          },
          onError: {
            target: 'loadingError',
          },
        },
      },
      paying: {
        invoke: {
          src: 'createTokenAndRedirect',
          onDone: {},
          onError: {
            target: 'loadingError',
          },
        },
      },
      loadingError: {},
    },
    on: {
      ADD_ADDRESS: { actions: ['addAddress'] },
      REMOVE_ADDRESS: { actions: ['removeAddress'] },
      SUBMIT: {
        actions: ['addEmails'],
        target: 'calculatePrice',
      },
    },
  };
}

export default createConfig;
