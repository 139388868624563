import React, { useEffect } from 'react';
import styled from 'styled-components';

import PaymentDetails from './PaymentDetails';
import NextPrevStepButtons from '../../NextPrevStepButtons/NextPrevStepButtons';

const ReviewPayment = ({ onSubmit, previousStep, send, formState }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <ReviewStyled>
      <button
        aria-label="Edit Applicant Information"
        className="edit-button"
        type="button"
        onClick={() => send('PREV_STEP')}
      >
        Back to application
      </button>
      <h2 className="title">Payment Summary</h2>
      <PaymentDetails state={formState} />
      <NextPrevStepButtons
        altText="Continue to Payment"
        nextStep={onSubmit}
        prevStep={previousStep}
      />
    </ReviewStyled>
  );
};

export default ReviewPayment;

const ReviewStyled = styled.div`
  max-width: 1120px;
  margin: 62px auto 112px;
  padding: 0 30px;
  .title {
    font-size: 25px;
    margin-bottom: 36px;
  }
  .edit-button {
    position: absolute;
    top: 40px;
    right: 100px;
    margin-left: auto;
    border: solid 2px #14527f;
    padding: 12px;
    min-width: ${props => (props.long ? '200px' : '100px')};
    text-align: center;
    font-weight: 600;
    font-size: 15px;
    color: #14527f;
    background-color: transparent;
    transition: all ease 0.3s;
    cursor: pointer;
    &:hover,
    &:focus {
      color: white;
      background-color: #14527f;
    }
  }
`;
