import React from 'react';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as Eye } from '../../../assets/eye.svg';
import { ReactComponent as Trash } from '../../../assets/trashicon.svg';
import { statusOptions } from '../../../queries_mutations/sharedVariables';
import { debug } from '../../../envConfig';
import { camelCase } from '../../../shared/functions';

const EvaluationRowItem = ({ item, admins, send, noRushServiceId }) => {
  const {
    paidDate,
    applicant,
    applicantInfo,
    evaluator,
    status,
    refNumber,
    id,
    deliveryInstructions,
    addedRush,
  } = item;
  const navigate = useNavigate();
  const reviewApplication = applicationId => {
    navigate(`/admin/evaluation-review/${applicationId}`);
  };
  let itemClass = '';
  if (deliveryInstructions?.rushApplicationServiceID !== noRushServiceId) {
    itemClass = 'rushApp';
  }
  if (addedRush) {
    itemClass = 'addedRush';
  }

  return (
    <tr className={itemClass}>
      <td>{refNumber || '--'}</td>
      <td>{paidDate ? dayjs(paidDate).format('MM/DD/YYYY') : '--'}</td>
      <td>
        {camelCase(applicantInfo?.firstName) || '--'}{' '}
        {applicantInfo?.lastName.toUpperCase() || '--'}
      </td>
      <td>{applicant.getPaidCount}</td>
      <td>
        {applicantInfo
          ? dayjs(applicantInfo?.birthday.slice(0, -2)).format('MM/DD/YYYY')
          : '--'}
      </td>
      <td>
        <select
          name="evaluators"
          id="evaluator-select"
          onChange={e => {
            send({
              type: 'UPDATE_EVALUATOR',
              data: { id, evaluator: e.target.value },
            });
          }}
          defaultValue={evaluator.id}
        >
          {admins.map(({ firstName, lastName, id: evalId }) => (
            <option key={evalId} value={evalId}>
              {firstName} {lastName.toUpperCase()}
            </option>
          ))}
        </select>
      </td>
      <td>
        <select
          name="status"
          id="evaluator-status"
          onChange={e => {
            send({
              type: 'UPDATE_STATUS',
              data: { id, status: e.target.value },
            });
          }}
          defaultValue={status}
        >
          {statusOptions.map(({ id: optionId, option }) => (
            <option key={optionId} value={option}>
              {option}
            </option>
          ))}
        </select>
      </td>
      <td>
        <button type="button" onClick={() => reviewApplication(id)}>
          <Eye />
          Review
        </button>
        {debug.enableAbilityToDeleteApplication && (
          <button
            type="button"
            onClick={() => send({ type: 'DELETE_APPLICATION', data: { id } })}
          >
            <Trash />
          </button>
        )}
      </td>
    </tr>
  );
};

export default EvaluationRowItem;
