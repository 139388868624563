import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

export const LinkText = styled.span`
  color: White;
  font-weight: 700;
  font-size: 14px;
  transition: all ease 0.3s;
`;
export const SidebarLink = styled(NavLink)`
  display: flex;
  width: 100%;
  padding: 12px 0;
  &:hover,
  &.active {
    background-color: #0c4973;
    position: relative;
    &:before {
      width: 3px;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      background-color: #68b6ec;
      content: '';
    }
  }
`;

export const LinkIcon = styled.span`
  width: 30px;
  display: inline-flex;
  justify-content: center;
  svg {
    fill: white;
  }
`;
export const SubMenu = styled.div`
  display: flex;
  flex-direction: column;
  height: ${({ showSubNav }) => (showSubNav ? '130px' : '0')};
  opacity: ${({ showSubNav }) => (showSubNav ? '1' : '0')};
  transition: height ease 0.3s, opacity linear 0.4s;
`;

export const DropdownOption = styled.div`
  cursor: pointer;
  background-color: ${({ showSubNav }) =>
    showSubNav ? '#0C4973' : 'transparent'};
  &.active {
    position: relative;
    &:before {
      width: 3px;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      background-color: #68b6ec;
      content: '';
    }
  }
`;

export const OptionContainer = styled.div`
  padding: 12px 0;
  display: flex;
  align-items: center;
`;
export const DropdownLink = styled(NavLink)`
  padding-left: 16px;

  div:hover,
  &.active {
    span {
      color: #68b6ec;
      transition: all ease 0.3s;
      &:first-child {
        background-color: #68b6ec;
      }
    }
  }
`;

export const SubLinkBar = styled.span`
  width: 10px;
  height: 3px;
  background-color: transparent;
  margin-right: 4px;
`;
