import React from 'react';
import styled from 'styled-components';

import { returnService } from '../../../../shared/functions';

const PaymentDetails = ({ state }) => {
  if (state.matches('editing.reviewPayment.waiting')) {
    const { services, cart } = state.context;

    const emailService = services.find(
      ser => ser.displayName === 'Additional Email',
    );
    let additionalEmails;
    if (cart.additionalPDFEmails)
      additionalEmails = JSON.parse(cart.additionalPDFEmails);
    return (
      <Section>
        <div className="header">
          <h2>Order Information</h2>
        </div>
        <div className="content">
          <div className="content__block content__block--title">
            <p>Service</p>
            <p>Price</p>
          </div>
          {/* APPLICATION SERVICE */}
          {cart.applicationService && (
            <div key={Math.random()} className="content__block">
              <p>Evaluation service: {cart.applicationService?.displayName}</p>
              <p>
                <span>$</span>
                {cart.applicationService?.price}.00
              </p>
            </div>
          )}
          {/* DELIVERY SERVICE */}
          {cart.deliveryServiceOption && (
            <div key={Math.random()} className="content__block">
              <p>Delivery: {cart.deliveryServiceOption?.displayName}</p>
              <p>
                <span>$</span>
                {cart.deliveryServiceOption?.price}.00
              </p>
            </div>
          )}
          {/* RUSH SERVICE */}
          {cart.rushService && (
            <div key={Math.random()} className="content__block">
              <p>Rush Service: {cart.rushService?.displayName}</p>
              <p>
                <span>$</span>
                {cart.rushService?.price}.00
              </p>
            </div>
          )}

          {/* RETURN DOCUMENTS */}
          {cart.returnDocumentsService && (
            <>
              <div className="content__block">
                <p>
                  Return Documents: {cart.returnDocumentsService?.displayName}
                </p>
                <p>
                  <span>$</span>
                  {cart.returnDocumentsService?.price}.00
                </p>
              </div>
            </>
          )}
          {/* ADDITIONAL REPORTS MAILED */}
          {cart.additionalReportsMailedAddresses.length > 0 && (
            <>
              <div className="content__block">
                <p>
                  Additional{' '}
                  {cart.additionalReportsMailedAddresses.length > 1
                    ? 'Addresses '
                    : 'Address '}
                  ({cart.additionalReportsMailedAddresses.length} added):
                </p>
              </div>
              {cart.additionalReportsMailedAddresses.map((address, index) => {
                const service = returnService(services, address.shippingMethod);
                return (
                  <div key={Math.random()} className="content__block">
                    <p>
                      {index + 1}. {service.displayName}
                    </p>
                    <p>
                      <span>$</span>
                      {service.price}.00
                    </p>
                  </div>
                );
              })}
            </>
          )}
          {/* ADDITIONAL EMAILED REPORTS */}
          {additionalEmails?.length > 0 && (
            <>
              <div className="content__block">
                <p>
                  Additional Email{' '}
                  {additionalEmails.length > 1 ? 'Reports' : 'Report'}:
                </p>
              </div>
              {additionalEmails?.map(({ email }) => {
                return (
                  <div key={Math.random()} className="content__block">
                    <p>{email}</p>
                    <p>
                      <span>$</span>
                      {emailService.price}.00
                    </p>
                  </div>
                );
              })}
            </>
          )}

          <div className="content__block content__block--total">
            <p>Total Amount:</p>
            <p>
              <span>$</span>
              {cart.total}.00
            </p>
          </div>
        </div>
      </Section>
    );
  }
  return (
    <Section>
      <div className="header">
        <h2>Loading Order Information...</h2>
      </div>
    </Section>
  );
};

export default PaymentDetails;

const Section = styled.div`
  background-color: #f7fafc;
  border-radius: 2px;
  margin-bottom: 40px;
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 30px 25px;
    border-bottom: solid 1px #d4d4d4;
    h2 {
      font-size: 16px;
      font-weight: 700;
    }
  }
  .content {
    &__block {
      display: flex;
      justify-content: left;
      align-items: center;
      box-sizing: content;
      &--title {
        p {
          color: #14527f;
          font-size: 15px;
          font-weight: 700;
          text-transform: uppercase;
        }
      }
      &--total {
        background-color: #edf2f7;
        p {
          text-transform: uppercase;
          font-weight: 700;
          &:first-child {
            color: #14527f;
          }
        }
      }
      p {
        padding: 13px 30px;
        &:first-child {
          width: 60%;
          border-right: solid 1px #d4d4d4;
          padding-left: 60px;
          padding-right: 20px;
          font-weight: 700;
        }
      }
      &:nth-last-child(2) {
        p {
          padding-bottom: 30px;
        }
      }
    }
  }
`;
