import { Machine, assign } from 'xstate';
import { useApolloClient } from '@apollo/client';

// IMPORT MACHINE CONFIG
import machineConfig from './MachineConfig';

// IMPORT QUERIES AND MUTATIONS
import copyRequestsQueries from './queries';
import copyRequestsMutations from './mutations';

function createServices() {
  // GET APOLLO CLIENT TO SEND TO MUTATIONS
  const client = useApolloClient();

  // GET QUERIES AND MUTATIONS FROM IMPORT
  const { init, search } = copyRequestsQueries(client);
  const { updateStatus } = copyRequestsMutations(client);

  return {
    guards: {
      // EXAMPLE OF GUARD
      // hasMore: context => {
      //   if (context.cursor) return true;
      //   return false;
      // },
    },

    services: {
      load: ctx => {
        return init(ctx.take);
      },
      search: (ctx, e) => {
        return search(e.data, ctx.take);
      },
      updateStatus: (_, e) => {
        const data = { status: e.value.status, id: e.value.id };
        return updateStatus(data);
      },
    },

    actions: {
      loadSuccess: assign({
        allCopyRequestsCount: (_, { data }) => data.totalCopyRequests,
        pages: (ctx, { data }) => {
          return Math.ceil(data.copyRequests.length / ctx.itemsToShow);
        },
        copyRequests: (_, { data }) => {
          return data.copyRequests;
        },
        message: () => null,
      }),
      searchSuccess: assign({
        copyRequests: (ctx, { data }) => {
          // console.log('items are: ', data.applications);
          return data.applications;
        },
        pages: (ctx, { data }) => {
          return Math.ceil(data.applications.length / ctx.itemsToShow);
        },
        message: (_, { data }) => {
          return data.applications.length === 0 ? 'No results found' : null;
        },
        currentPage: 1,
      }),
      updateApplicationList: assign({
        copyRequests: (ctx, { data }) => {
          const newArray = ctx.copyRequests.filter(i => i.id !== data);
          return newArray;
        },
      }),
      nextPage: assign({
        currentPage: ctx => {
          return ctx.currentPage + 1;
        },
      }),
      prevPage: assign({
        currentPage: ctx => {
          return ctx.currentPage - 1;
        },
      }),
      goToPage: assign({
        currentPage: (_, e) => {
          return e.page;
        },
      }),
      setDeleteItem: assign({
        itemToDelete: (_, e) => {
          return e.data;
        },
      }),
      setDefaults: assign({
        itemToDelete: '',
      }),
      sort: assign({
        sortRef: (ctx, e) => {
          if (e.sort === 'SORT_REF_NUM') {
            return !ctx.sortRef;
          }
          return null;
        },
        sortDatePaid: (ctx, e) => {
          if (e.sort === 'SORT_DATE_PAID') {
            return !ctx.sortDatePaid;
          }
          return null;
        },
        sortName: (ctx, e) => {
          if (e.sort === 'SORT_NAME') {
            return !ctx.sortName;
          }
          return null;
        },
        sortStatus: (ctx, e) => {
          if (e.sort === 'SORT_STATUS') {
            return !ctx.sortStatus;
          }
          return null;
        },
      }),
    },
  };
}

function create() {
  // CREATE ALL SERVICES FROM ABOVE
  const services = createServices();

  // RETURN MACHINE WITH CONFIG AND SERVICES
  return Machine({ ...machineConfig() }, { ...services });
}

export default create;
