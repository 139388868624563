import { Machine, assign, send } from 'xstate';
import { useApolloClient } from '@apollo/client';
import { returnService } from '../../../shared/functions';
// IMPORT MACHINE CONFIG
import machineConfig from './MachineConfig';

// IMPORT QUERIES AND MUTATIONS
import queries from './queries';
import mutations from './mutations';

function createServices() {
  // GET APOLLO CLIENT TO SEND TO MUTATIONS
  const client = useApolloClient();

  // GET QUERIES AND MUTATIONS FROM IMPORT
  const { getServices } = queries(client);
  const { createCopyRequest, payCopyRequest } = mutations(client);

  return {
    guards: {},

    services: {
      getServices: () => {
        return getServices();
      },
      createCopyRequest: ctx => {
        return createCopyRequest(ctx);
      },
      createTokenAndRedirect: ctx => {
        return payCopyRequest({
          applicationID: ctx.applicationId,
          copyRequestID: ctx.copyRequestID,
        });
      },
    },

    actions: {
      addAddress: (ctx, e) => {
        ctx.addresses.push(e);
      },
      removeAddress: (ctx, e) => {
        ctx.addresses.splice(e, 1);
      },
      addServices: assign({
        services: (_, e) => {
          return e.data.services;
        },
        emailService: (_, e) => {
          return e.data.emailService;
        },
        returnDocsEmailService: (_, e) => {
          return (
            e?.data.returnDocsServices?.find(({ description }) =>
              description.toLowerCase().includes('email'),
            ) || null
          );
        },
        returnDocsServices: (_, e) => {
          return e.data.returnDocsServices.filter(
            ({ description }) => !description.toLowerCase().includes('email'),
          );
        },
      }),
      saveCopyRequestID: assign({
        copyRequestID: (_, e) => {
          return e.data.copyRequestId;
        },
      }),
      addEmails: assign({
        emailPDFS: (_, e) => {
          // FORM SENDS AN OBJECT OF OBJECTS, OBJECT PAIR TYPE:'SUBMIT' NEEDS TO BE REMOVED
          // // CREATE ARRAY FROM LEFT OVER DATA OF EMAILS.
          const newArray = Object.entries(e.ADDITIONAL_PDFS_EMAILS).map(
            ob => ob[1].email,
          );
          return newArray;
        },
        returnDocs: (_, e) => {
          if (e.return_docs_checkbox) {
            if (e.return_docs_option === 'mail') {
              return e.return_docs;
            }
            return { returnEmail: e.return_docs_email, email: true };
          }
          return false;
        },
      }),
      calculatePrice: assign({
        totalPrice: ctx => {
          // PRICE FOR ALL EMAILS
          const emailPrice = ctx.emailPDFS.length * ctx.emailService.price || 0;
          // PRICE FOR RETURN DOCUMENTS
          const returnDocsByEmailPrice = ctx?.returnDocs?.email
            ? ctx?.returnDocsEmailService?.price
            : 0;
          // PRICE FOR RETURN DOCUMENTS BY MAIL
          let returnDocsByMailPrice = 0;
          if (ctx?.returnDocs?.shipping_method) {
            const { price: servicePrice } = returnService(
              ctx.returnDocsServices,
              ctx?.returnDocs?.shipping_method,
            );
            returnDocsByMailPrice += servicePrice;
          }

          // PRICE FOR EACH ADDRESS BASED ON MAILING SERVICE
          let addressPrice = 0;
          ctx.addresses.map(({ shippingMethod }) => {
            const { price: servicePrice } = returnService(
              ctx.services,
              shippingMethod,
            );
            addressPrice += servicePrice;
            return true;
          });

          // RETURN PRICE OF BOTH TOGETHER
          return (
            emailPrice +
            addressPrice +
            returnDocsByEmailPrice +
            returnDocsByMailPrice
          );
        },
      }),
      sendSubmit: send('COMPLETE'),
      finished: send('COMPLETE'),
    },
  };
}

function create(id) {
  // CREATE ALL SERVICES FROM ABOVE
  const services = createServices();

  // RETURN MACHINE WITH CONFIG AND SERVICES
  return Machine({ ...machineConfig(id) }, { ...services });
}

export default create;
