import React, { StrictMode, useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import {
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes,
} from 'react-router-dom';

import { inspect } from '@xstate/inspect';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { inject } from '@vercel/analytics';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

import config, { debug, sentryConfig } from './envConfig';
import App from './App';
import ApolloAuthProvider from './components/auth/ApolloAuthProvider';
import { Auth0ProviderWithNavigate } from './components/auth/Auth0ProviderWithNavigate';

if (debug.xstate) {
  inspect({
    url: 'https://statecharts.io/inspect', // (default)
    iframe: false, // open in new window
  });
}

// Vercel Analytics
inject();

Sentry.init({
  ...sentryConfig,
  integrations: [
    new Integrations.BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV6Instrumentation(
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      ),
    }),
    new Sentry.Replay(),
  ],
  attachStacktrace: true,
  tracesSampleRate: 1.0,
  tracePropagationTargets: ['localhost', config.apiUrl],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <StrictMode>
    <Sentry.ErrorBoundary fallback={<p>An error has occurred.</p>}>
      <Auth0ProviderWithNavigate>
        <ApolloAuthProvider>
          <ToastContainer
            style={{
              lineHeight: '1.5',
              width: '50vw',
              maxWidth: '600px',
            }}
          />
          <App />
        </ApolloAuthProvider>
      </Auth0ProviderWithNavigate>
    </Sentry.ErrorBoundary>
  </StrictMode>,
);
